@tailwind base;
@tailwind components;

$spacing: 16px;

html {
  background-color: #0e0e0e;
}

.flex-center {
  @apply flex items-center justify-center;
}

.faq-details p.nested {
  margin-top: 0 !important;
}

.faq-details a {
  @apply font-medium underline text-charcoal;
}

.faq-details b {
  @apply font-heavy;
}
.notice a {
  @apply block md:inline;
}

.text-gradient {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.cta {
  @apply uppercase font-cta;
}

button:disabled,
input:disabled {
  cursor: not-allowed !important;
  @apply shadow-none;
}

.slanted {
  transform: skew(-12deg);
  -webkit-transform: skew(-12deg);
  -moz-transform: skew(-12deg);
  -o-transform: skew(-12deg);
}

.hue-filter-50:not(:disabled):hover,
button:not(:disabled, .no-highlight):hover {
  filter: brightness(125%) hue-rotate(2deg);
  transition: filter 0.15s ease-in-out;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.heading {
  @apply font-extrabold tracking-wide uppercase font-display;
}
.heading.alone {
  @apply text-h1 lg:text-lg-h1;
}
.heading.upper {
  @apply text-super lg:text-lg-super;
}
.heading.below {
  @apply text-duper lg:text-lg-duper;
}
.body {
  @apply text-sub lg:text-lg-sub;
}

.center {
  @apply flex items-center justify-center;
}

.review-details b {
  @apply font-bold text-white text-opacity-100;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbars-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbars-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* carousel start */
$spacing: 16px;

.carousel {
  display: flex;
  flex-direction: row;

  padding: $spacing 0;
  margin: $spacing ($spacing * -1);

  overflow-x: auto;
}

.horse {
  flex: 0 0 100%;

  display: flex;
  padding: 0 ($spacing / 2);
  box-sizing: border-box;

  list-style-type: none;
}

.card {
  flex: 1 1 0;
  margin: 0 ($spacing / 2);
  border-radius: 8px;
  box-shadow: 0 0 4px #333;
  background: white;
  min-height: 160px;
}

.card-spacer {
  flex: 1 1 0;
}
.carousel {
  scroll-snap-type: x mandatory;
  overscroll-behavior: contain;
}

.horse {
  scroll-snap-align: start;
}

/* carousel end */

.bg-dark-slanted-gradient {
  background: linear-gradient(122.48deg, #3e3e3e 12.96%, #1f1f1f 70.01%);
}

b,
strong {
  font-family: "SFPro-Display-Heavy";
}

/* countdown svg */
svg.countdown {
  transform: rotateY(-180deg) rotateZ(-90deg);
}

body {
  --countdown-duration: 5s;
}

svg.countdown circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 4px;
  stroke: #e6ff00;
  fill: none;
  animation: countdown var(--countdown-duration) linear forwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}

@keyframes fade-in-animate {
  0% {
    opacity: 0;
    filter: brightness(1) blur(10px);
  }
  10% {
    opacity: 1;
    filter: brightness(1.25) blur(5px);
  }
  100% {
    opacity: 1;
    filter: brightness(1) blur(0);
  }
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 6px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@keyframes floating-shadow {
  0% {
    scale: 1;
  }
  50% {
    scale: 0.7;
  }
  100% {
    scale: 1;
  }
}

.floating-shadow {
  animation-name: floating-shadow;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.floating {
  animation-name: floating;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.fade-in {
  animation: fade-in-animate 0.8s linear forwards;
}

.markdown ol {
  counter-reset: orderedlist;
}
.review-details .markdown strong {
  color: white;
}
.markdown ol li {
  @apply flex items-center justify-start text-lg text-white/80;
}
.markdown ol li::before {
  counter-increment: orderedlist;
  content: counter(orderedlist);
  @apply flex items-center justify-center w-12 h-12 p-6 mr-5 text-2xl font-bold border-2 rounded-full font-display text-white/75 border-lemon;
}

.iphone {
  position: relative;
  /* margin: 40px auto;
  width: 400px;
  height: 900px; */
  border-radius: 40px;
}
.notch {
  top: 0;
  border-radius: 0 0 28px 28px;
}
.notch::before,
.notch::after {
  content: "";
  position: absolute;
  top: 0;
  left: -7px;
  width: 14px;
  height: 7px;
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-image: radial-gradient(
    circle at 0 100%,
    transparent 6px,
    #222 7px
  );
}
.notch::after {
  left: 100%;
  margin-right: corner-sizepx;
  background-image: radial-gradient(
    circle at 100% 100%,
    transparent 6px,
    #222 7px
  );
}

.bottom-apple-padding {
  bottom: 0;
}
@supports (padding: max(0px)) {
  .apple-padding {
    padding-top: env(safe-area-inset-top);
  }
  .bottom-apple-padding {
    /*Default padding*/
    padding-bottom: 4px;
    padding-bottom: env(safe-area-inset-bottom);
  }
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

/* disable double tap to zoom */
button {
  touch-action: manipulation;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.shimmer {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  background: radial-gradient(white, #3984ff00 80%);
  opacity: 0;
  transition: opacity 0.2s;
}

.scroll-down-indicator {
  border-radius: 20px;
  height: 50px;
  width: 30px;
}
.scroll-down-indicator::before {
  animation: scrollDownAnimation 2s infinite;
  background-color: #fff;
  border-radius: 100%;
  content: "";
  height: 6px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 6px;
}
@-moz-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}
@keyframes scrollDownAnimation {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
  }
}

@tailwind utilities;
